.area-row-container {
  width: 100%;
  height: 100%;
  &.is-over {
    opacity: 0.5;
  }
  .area-row-header-container {
    display: flex;
    height: 45px;
    background: #eaeaea;
    .area-row-header-name-container {
      display: flex;
      align-items: center;
      width: 592px;
      height: 100%;
      padding: 10px 0 10px 20px;
      .area-row-header-name {
        font-family: 'Prompt-Medium', sans-serif;
        font-size: 16px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: rgba(31, 45, 57, 0.7);
        margin-right: 30px;
      }
    }
    .area-row-header-option-container {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 490px;
      height: 100%;
      .area-row-header-option-btn {
        margin-left: 20px;
        font-size: 14px;
      }
    }
    .area-row-header-remove-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 108px;
      height: 100%;
    }
    .area-row-header-btn {
      font-size: 14px;
    }
  }
}
